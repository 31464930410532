import { Module, VuexModule, Action, Mutation } from "vuex-module-decorators";
import { unref } from "vue";
import { Endpoints } from "@/store/enums/ApiEnums";
import AxiosService from "@/services/AxiosService";
import useRemoveDuplicates from "@/composables/removeDuplicates";

@Module({ namespaced: true })
class AdChannelsInCampaignsStore extends VuexModule {
  public Items = {};
  public Channels = {};
  public CampaignId = null;
  public ChannelId = null;
  public url = "";
  public data1 = "";
  public data2 = "";
  public RefLink = { url: "", error: "" };
  public RefLinkArr = [] as any;

  get fetchedItems() {
    return this.Items;
  }

  get fetchedChannels() {
    return this.Channels;
  }

  get refLink() {
    return this.RefLink;
  }

  get refLinkArr() {
    return this.RefLinkArr;
  }

  @Mutation
  CLEAR_ITEMS(): void {
    this.Items = {};
  }

  @Action
  public clearItems() {
    this.context.commit("CLEAR_ITEMS");
  };

  @Mutation
  CLEAR_ADCHANNELS(): void {
    this.Channels = [];
  }

  @Action
  public clearAdChannels() {
    this.context.commit("CLEAR_ADCHANNELS");
  };

  @Mutation
  SET_ITEMS(payload: any): void {
    this.Items = payload;
  }

  @Mutation
  SET_CHANNELS(payload: any): void {
    this.Channels = payload;
  }

  @Mutation
  SET_CAMPAIGN_ID(payload: any): void {
    this.CampaignId = payload;
  }

  @Mutation
  SET_CHANNEL_ID(payload: any): void {
    this.ChannelId = payload;
  }

  @Mutation
  SET_URL(payload: any): void {
    this.url = payload;
  }

  @Mutation
  SET_DATA1(payload: any): void {
    this.data1 = payload;
  }

  @Mutation
  SET_DATA2(payload: any): void {
    this.data2 = payload;
  }

  @Mutation
  SET_REF_LINK(payload: any): void {
    this.RefLink = payload;
  }

  @Mutation
  PUSH_IN_REFLINK_ARR(payload: any): void {
    this.RefLinkArr.push(payload);
  }

  @Mutation
  CLEAR_REF_LINK(): void {
    this.RefLink = { url: "", error: "" };
  }
  @Mutation
  CLEAR_REF_LINK_ARR(): void {
    this.RefLinkArr = [];
  }

  @Action
  public setCampaignId(val) {
    this.context.commit("SET_CAMPAIGN_ID", val);
  }

  @Action
  public setChannelId(val) {
    this.context.commit("SET_CHANNEL_ID", val);
  }

  @Action
  public setUrl(val) {
    this.context.commit("SET_URL", val);
  }

  @Action
  public setData1(val) {
    this.context.commit("SET_DATA1", val);
  }

  @Action
  public setData2(val) {
    this.context.commit("SET_DATA2", val);
  }

  @Action
  public getCampaignId() {
    return this.CampaignId;
  }

  @Action
  public getChannelId() {
    return this.ChannelId;
  }

  @Action
  public getUrl() {
    return this.url;
  }

  @Action
  public getData1() {
    return this.data1;
  }

  @Action
  public getData2() {
    return this.data2;
  }

  @Action
  public approvedAdChannelsInCampaigns(query: string | string[]) {
    const filter = "?filter[status][eq]=1&expand[]=channel&expand[]=campaign";
    const q = unref(query);
    if (q.length > 2) {
      const queryTerm = "&filter[campaign.name][like]=" + q;
      AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS_IN_CAMPAIGN_BASE + filter + queryTerm).then((response) => {
        const data = response.data.data;
        const { noDuplicates } = useRemoveDuplicates("campaign_id", data);
        const noNullCampaigns = noDuplicates.filter((i) => i.campaign);
        this.context.commit("SET_ITEMS", noNullCampaigns);
      });
    } else {
      AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS_IN_CAMPAIGN_BASE + filter).then((response) => {
        const data = response.data.data;
        const { noDuplicates } = useRemoveDuplicates("campaign_id", data);
        const noNullCampaigns = noDuplicates.filter((i) => i.campaign);
        this.context.commit("SET_ITEMS", noNullCampaigns);
      });
    }
  }

  @Action
  public adChannelsOfGivenCampaign(query: string | string[]) {
    const filter = "?filter[status][eq]=A&expand[]=adChannels";
    const q = unref(query);
    if (q.length > 2) {
      const queryTerm = "&filter[name][like]=" + q;
      AxiosService.get(Endpoints.ENDPOINT_CAMPAIGN + "/" + this.CampaignId + filter + queryTerm).then((response) => {
        const data = response.data.data.ad_channels;
        this.context.commit("SET_CHANNELS", data);
      });
    } else {
      AxiosService.get(Endpoints.ENDPOINT_CAMPAIGN + "/" + this.CampaignId + filter).then((response) => {
        const data = response.data.data.ad_channels;
        this.context.commit("SET_CHANNELS", data);
      });
    }
  }

  @Action
  public generateLink(body) {
    const b = unref(body);
    AxiosService.post(Endpoints.ENDPOINT_CAMPAIGN_GENERATE_LINK, b, false)
      .then((response) => {
        const data = response.data;
        const configData = JSON.parse(response.config.data);
        const typeUrl = configData.url_type;
        const order = configData.order;
        data["url_type"] = data.type_url ?? typeUrl;
        data["order"] = order;
        this.context.commit("SET_REF_LINK", data);
        this.context.commit("PUSH_IN_REFLINK_ARR", data);
      })
      .catch((error) => {
        const configData = JSON.parse(error.response.config.data);
        const order = configData.order;
        this.context.commit("SET_REF_LINK", {
          error: error.response.data.message,
          status: error.response.data.status,
          order,
        });
        this.context.commit("PUSH_IN_REFLINK_ARR", {
          error: error.response.data.message,
          status: error.response.data.status,
          order,
        });
      });
  }

  @Action
  public setCustomLink(link) {
    this.context.commit("SET_REF_LINK", link);
  }

  @Action
  public clearLink() {
    this.context.commit("CLEAR_REF_LINK");
  }

  @Action
  public clearRefLinkArr() {
    this.context.commit("CLEAR_REF_LINK_ARR");
  }

  @Action
  public async fetchAdChannelsForCampaignId(id) {
    await AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS_IN_CAMPAIGN_BASE + "?filter[campaign_id][eq]=" + id + "&expand[]=channel").then(
      (response) => {
        this.context.commit("SET_ITEMS", response?.data.data);
      }
    );
  };

  @Action
  public async getAprovedChannelsOfLogedUser() {
    await AxiosService.get(Endpoints.ENDPOINT_AD_CHANNELS).then((response) => {
      const ch = response.data.data.filter((e) => {
        return e.status == 1;
      });
      this.context.commit("SET_CHANNELS", ch);
    });
  };

}

export default AdChannelsInCampaignsStore;
